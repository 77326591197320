<template>
  <v-container>
    <div class="back-plot">
      <!-- BOC:[error] -->
      <ApiErrorDialog v-if="api.isError" :api="api" />
      <!-- EOC -->
      <v-card class="transparent" elevation="0" v-if="classroom">
        <v-card-title class="text-h5 grey lighten-5 rounded-lg py-2">
          <v-row>
            <v-col cols="9" class="d-flex rounded align-center">
              {{ classroom.name }}
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <CloseBtn size="40" :callback="exit" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <!-- EOC -->
      <v-card
        :class="
          settings.graphics && settings.graphics.mode == 'low'
            ? 'low_plot'
            : 'plot'
        "
        :loading="api.isLoading"
      >
        <v-card-title>{{
          $t("view.PageEntranceStudentRegister.title")
        }}</v-card-title>
        <v-card-text v-if="classroom">
          <FormRegister :classroomId="classroom.id" />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    FormRegister: () =>
      import(
        /* webpackChunkName: "component-entrance-register" */ "@/components/entrance/FormRegister"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    selectedStudent: null,
    student: null,
    code: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    classroom: {},
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    if (this.auth.Group) {
      this.code = this.auth.Group.code;
      if (this.auth && this.auth.Classroom) {
        this.classroom = this.auth.Classroom;
      }
    } else {
      this.$router.push({ name: "PageAccessMain" });
    }
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageAccessClassroom",
      });
    },
  },
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.image1 {
  position: absolute;
  z-index: 2;
}
.image2 {
  position: absolute;
  z-index: 3;
}

.image3 {
  position: absolute;
  z-index: 1;
}

.title {
  pointer-events: none;
  font-size: 1em !important;
}
</style>